import React from "react";
import { Navigate } from "react-router-dom";

import { auth } from "../../firebase";

const RequireLogin = ({ children }) => {
  if (auth.currentUser) {
    return <>{children}</>;
  }
  return <Navigate to="/signin" />;
};

export default RequireLogin;
