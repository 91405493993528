import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";

import Header from "./components/Header/Header";
import CreateNID from "./pages/CreateNID";
import Profile from "./pages/Profile";
import Activities from "./pages/Activities";
import Security from "./pages/Security";
import Verification from "./pages/Verification";

import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import { useAuthContext } from "./context/AuthContext";
import PublicRoute from "./components/AuthRoutes/PublicRoute";
import PrivateRoute from "./components/AuthRoutes/PrivateRoute";
import { auth } from "./firebase";
import set_user_info from "./utils/set_user_info";
import LoadingScreen from "./components/Loading";
import ResetPassword from "./pages/ResetPassword";
import TostMessage from "./components/TostMessage";
import PrintPdfWrapper from "./pages/PrintPdfWrapper";
import History from "./pages/History";
import NotActiveStatus from "./components/NotActiveStatus";
import RequireLogin from "./components/HOC/RequireLogin";
const AllRoutes = () => {
  const { state, dispatch } = useAuthContext();
  const [user, set_user] = useState();
  const [loading_screen, set_ls] = useState("1");

  useEffect(() => {
    if (user?.email) {
      set_user_info(dispatch, user);
    }
  }, [user, dispatch]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      set_user(user);
      setTimeout(() => {
        set_ls("2");
      }, 500);
    });
  }, [dispatch]);

  useEffect(() => {
    const action = localStorage.getItem("action");
    if (action === "login") {
      TostMessage("Successfully Logged In!", null, 900);
      localStorage.removeItem("action");
    }

    if (action === "register") {
      TostMessage("Successfully Registered!", null, 900);
      localStorage.removeItem("action");
    }
  }, [state]);

  if (loading_screen === "1") return <LoadingScreen />;

  return (
    <main>
      <NotActiveStatus />

      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <RequireLogin>
              <Home />
            </RequireLogin>
          }
        />

        <Route
          path="/create-nid"
          element={
            <RequireLogin>
              <CreateNID />
            </RequireLogin>
          }
        />

        <Route
          path="/preview-nid"
          element={
            <RequireLogin>
              <PrintPdfWrapper />
            </RequireLogin>
          }
        />

        <Route
          path="/profile"
          element={
            <RequireLogin>
              <Profile />
            </RequireLogin>
          }
        />
        <Route
          path="/activities"
          element={
            <RequireLogin>
              <Activities />
            </RequireLogin>
          }
        />
        <Route
          path="/history"
          element={
            <RequireLogin>
              <History />
            </RequireLogin>
          }
        />
        <Route
          path="/security"
          element={
            <RequireLogin>
              <Security />
            </RequireLogin>
          }
        />
        <Route
          path="/verification"
          element={
            <RequireLogin>
              <Verification />
            </RequireLogin>
          }
        />

        {/* Auth Route */}
        <Route
          path="/signin"
          element={
            <PublicRoute>
              <Signin />
            </PublicRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <RequireLogin>
              <ResetPassword />
            </RequireLogin>
          }
        />

        <Route
          path="/signup"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <ToastContainer />
    </main>
  );
};

export default AllRoutes;
