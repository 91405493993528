/*
new Date(date_of_birth?.trim()).toUTCString().split(" ").splice(1, 3).join("")

 // custom bar code
  const [imageData, setImageData] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://barcode-generator.onrender.com/api/v1/barcode/pdf417?value=${value}`
      )
      .then((res) => setImageData(res.data))
      .catch((error) => console.log(error?.message));
  }, []);

  return <img className="w-full h-[41px]" src={imageData} alt="" />;

  // tech-it

    */

import { useEffect, useState } from "react";
import axios from "axios";
const BarCode417 = ({ value }) => {
  return (
    <img
      className="w-full h-[39px]"
      src={`https://barcode.tec-it.com/barcode.ashx?data=${value}&code=PDF417`}
      alt=""
    />
  );
};

export default BarCode417;
