import { createContext, useReducer, useContext, useEffect } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import set_auth_error from "../utils/set_auth_error";
import set_user_info from "../utils/set_user_info";

const AuthState = {
  user: {},
  error: "",
  loading: false,
  updating: false,
  authenticated: false,
};

const reducer = (state = AuthState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "set_user":
      return { ...state, user: { ...payload } };

    case "set_loading":
      return { ...state, loading: true };

    case "reset_loading":
      return { ...state, loading: false };

    case "set_updating":
      return { ...state, updating: true };

    case "reset_updating":
      return { ...state, updating: false };

    case "set_error":
      return { ...state, error: payload };

    case "reset_error":
      return { ...state, error: "" };

    case "set_authenticated":
      return { ...state, authenticated: true };

    case "logout":
      return {
        ...state,
        user: {},
        error: "",
        message: "",
        loading: false,
        authenticated: false,
      };

    default:
      return state;
  }
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, AuthState);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        set_user_info(dispatch, user);
      }
    });
  }, []);

  function register(form) {
    dispatch({ type: "set_loading" });
    return createUserWithEmailAndPassword(auth, form?.email, form?.password)
      .then(() => {
        const object_for_photoURL = {
          photo:
            "https://cdn-icons-png.flaticon.com/512/149/149071.png",
        };
        updateProfile(auth.currentUser, {
          displayName: form?.name,
          photoURL: JSON.stringify(object_for_photoURL),
        }).then(() => {
          auth.onAuthStateChanged((user) => {
            set_user_info(dispatch, user);

            localStorage.setItem("action", "register");
          });
        });
      })
      .catch((error) => {
        set_auth_error(dispatch, error?.code?.split("-").join(" "));
      })
      .finally(() => {
        dispatch({ type: "reset_loading" });
      });
  }

  function logout() {
    dispatch({ type: "set_loading" });

    return signOut(auth).then(() => {
      setTimeout(() => {
        dispatch({ type: "logout" });
      }, 600);
    });
  }

  function login(email, password) {
    dispatch({ type: "set_loading" });

    return signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        auth.onAuthStateChanged((user) => {
          set_user_info(dispatch, user);

          localStorage.setItem("action", "login");
        });
      })

      .catch((error) => {
        set_auth_error(dispatch, error?.code?.split("-").join(" "));
      })
      .finally(() => {
        dispatch({ type: "reset_loading" });
      });
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        register,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
