import React, { useRef, useState } from "react";
import Upload from "../assets/images/upload.png";

import Layout from "../components/Layout/Layout";

import { Navigate } from "react-router-dom";
import { useNIDContext } from "../context/NIDContext";
import { ref, update } from "firebase/database";
import { db } from "../firebase";
import { useAuthContext } from "../context/AuthContext";
import firebase_upload_image from "../utils/firebase_upload_image";
import { uid } from "uid";
import { Loading2 } from "./../components/Loading";
import axios from "axios";

const CreateNID = () => {
  const { state, dispatch } = useNIDContext();
  const {
    state: { user },
  } = useAuthContext();
  const [creating, set_creating] = useState(false);
  const [preview_nid, set_preview_nid] = useState(false);

  const blood_group_input_ref = useRef();
  const [form_state, set_form_state] = useState({ ...state.nid });
  const [image_sign, set_image_sign] = useState(true);

  function handle_change(e) {
    const id = e.target.id;
    if (id === "nid_photo") {
      const file = e.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const uploaded_image = reader.result;
        const outer = document.querySelector("#preview_nid_photo");
        outer.innerHTML = `<div class="w-full h-full bg-black animate-pulse"></div>`;
        firebase_upload_image(file, null, (url) => {
          if (url) {
            outer.innerHTML = `<img class="w-full h-full object-cover" src="${uploaded_image}" alt="" />`;
            set_form_state((p) => ({ ...p, [id]: url }));
          }
        });
      });
      reader.readAsDataURL(file);
    } else if (id === "nid_signature") {
      const file = e.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const uploaded_image = reader.result;
        const outer = document.querySelector("#nid_signature_image_preview");
        outer.innerHTML = `<div class="w-full h-full bg-black animate-pulse"></div>`;
        firebase_upload_image(file, null, (url) => {
          if (url) {
            outer.innerHTML = `<img class="w-full h-full object-cover" src="${uploaded_image}" alt="" />`;
            set_form_state((p) => ({ ...p, [id]: url }));
          }
        });
      });
      reader.readAsDataURL(file);
    } else {
      set_form_state((p) => ({ ...p, [id]: e.target.value }));
    }
  }

  function address_suggest_handle(e) {
    set_form_state((p) => ({
      ...p,
      address: (p?.address ? p.address + " " : "") + e.target.innerText,
    }));
  }

  function set_blood(e) {
    set_form_state((p) => ({ ...p, blood_group: e.target.innerText }));
  }

  function submit_handle(e) {
    e.preventDefault();
    set_creating(true);

    dispatch({
      type: "set_nid_info",
      payload: form_state,
    });

    update(ref(db, `/${user?.uid}`), {
      [uid()]: {
        ...form_state,
        createdAt: new Date().toDateString(),
        createdTime: new Date(),
      },
    })
      .then(() => console.log("added nid info"))
      .catch((error) => console.log("nid adding error: ", error?.message))
      .finally(() => {
        set_preview_nid(true);
        set_creating(false);
      });
  }

  function reset_form() {
    set_form_state({});
  }

  function paste_handle(id, multiple) {
    if (multiple) {
      navigator.clipboard?.readText().then((copiedText) => {
        set_form_state((p) => {
          return {
            ...p,
            [id]: (p[id] ? p[id] + " " : p[id]) + copiedText.trim(),
          };
        });
      });
    } else {
      navigator.clipboard?.readText().then((copiedText) => {
        set_form_state((p) => ({ ...p, [id]: copiedText.trim() }));
      });
    }
  }

  const [pdfExtracting, setPdfExtracting] = useState(false);
  function handlePdfFile(e) {
    const file = e?.target?.files[0];
    if (!file) return;

    setPdfExtracting(true);
    const formData = new FormData();
    formData.append("multiplefileupload", file, file.name);

    const obj = {
      data: {
        val1: "value",
        val2: "value",
      },
    };

    obj.data.val3 = "value";

    axios
      .post("https://mr24.xyz/extract-pdf-data.php", formData)
      .then((res) => {
        const data = res.data?.data;
        set_form_state((p) => ({
          ...p,
          birth_place: data?.birthPlace?.trim(),
          blood_group: data?.bloodGroup?.trim(),
          date_of_birth: data?.dob?.trim(),
          address: data?.fulladdress?.trim(),
          nid_photo: data?.imageUrl1?.trim(),
          nid_signature: data?.imageUrl2?.trim(),
          name_bn: data?.nameBangla?.trim(),
          name_en: data?.nameEnglish?.trim(),
          father_name: data?.nameFather?.trim(),
          mother_name: data?.nameMother?.trim(),
          nid_no: data?.nid?.trim(),
          nid_pin: data?.pin?.trim(),
        }));

        const preview_photo = document.querySelector("#preview_nid_photo");
        preview_photo.innerHTML = `<img class="w-full h-full object-cover" src="${data?.imageUrl1}" alt="" />`;

        const nid_signature = document.querySelector(
          "#nid_signature_image_preview"
        );
        nid_signature.innerHTML = `<img class="w-full h-full object-cover" src="${data?.imageUrl2}" alt="" />`;
      })
      .catch(console.log)
      .finally(() => {
        setPdfExtracting(false);
      });
  }

  if (preview_nid) {
    return <Navigate to="/preview-nid" />;
  }

  return (
    <Layout>
      {pdfExtracting && <Loading2 />}

      <div className="w-full">
        <div className="bg-gray-50 text-blue-600 text-lg font-medium tracking-wide mb-5 p-4 rounded">
          Fill-UP ALL INFORMATIONS
        </div>
        <div className="pb-6 p-2 flex flex-col space-y-1 text-green-700">
          <label htmlFor="pdfdoc">
            {pdfExtracting ? "Uploading..." : "Upload PDF Document"}
          </label>
          <input
            id="pdfdoc"
            type="file"
            accept=".pdf"
            tabIndex="-1"
            onChange={handlePdfFile}
          />
        </div>
        <form
          onSubmit={submit_handle}
          className="grid grid-cols-12 gap-y-8 gap-x-6"
        >
          <div className="base_input_wrapper">
            <label htmlFor="name_bn">
              <span>নাম</span>
              <small>(বাংলায়)</small> *
            </label>

            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="name_bn"
                name="name_bn"
                value={form_state?.name_bn ? form_state.name_bn : ""}
                className="base_input"
                placeholder="সম্পুর্ন নাম বাংলায়"
              />
              <button
                onClick={() => paste_handle("name_bn")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="name_en">
              <span>নাম</span>
              <small>(ইংরেজিতে)</small> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="name_en"
                name="name_en"
                value={form_state?.name_en ? form_state.name_en : ""}
                className="base_input"
                placeholder="সম্পুর্ন নাম ইংরেজিতে"
              />
              <button
                onClick={() => paste_handle("name_en")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="father_name">
              <span>পিতার নাম</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="father_name"
                name="father_name"
                value={form_state?.father_name ? form_state.father_name : ""}
                className="base_input"
                placeholder="পিতার নাম"
              />
              <button
                onClick={() => paste_handle("father_name")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="mother_name">
              <span>মাতার নাম</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="mother_name"
                name="mother_name"
                value={form_state?.mother_name ? form_state.mother_name : ""}
                className="base_input"
                placeholder="মাতার নাম"
              />
              <button
                onClick={() => paste_handle("mother_name")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper full">
            <label htmlFor="address">
              <span>ঠিকানা</span> *
              <div className="flex gap-x-2">
                <button
                  onClick={address_suggest_handle}
                  className="text-sm px-2 py-1 focus:ring-2 rounded bg-blue-600 text-white"
                  type="button"
                >
                  বাসা/হোল্ডিং:
                </button>

                <button
                  onClick={address_suggest_handle}
                  className="text-sm px-2 py-1 focus:ring-2 rounded bg-blue-600 text-white"
                  type="button"
                >
                  গ্রাম/রাস্তা:
                </button>

                <button
                  onClick={address_suggest_handle}
                  className="text-sm px-2 py-1 focus:ring-2 rounded bg-blue-600 text-white"
                  type="button"
                >
                  ডাকঘর:
                </button>

                <button
                  onClick={address_suggest_handle}
                  className="text-sm px-2 py-1 focus:ring-2 rounded bg-blue-600 text-white"
                  type="button"
                >
                  সিটি কর্পোরেশন
                </button>
                <button
                  onClick={address_suggest_handle}
                  className="text-sm px-2 py-1 focus:ring-2 rounded bg-blue-600 text-white"
                  type="button"
                >
                  পৌরসভা
                </button>
              </div>
            </label>

            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="address"
                name="address"
                value={form_state?.address ? form_state.address : ""}
                className="base_input pr-[70px]"
                placeholder="আপনার সম্পুর্ন ঠিকানা লিখুন"
              />
              <button
                onClick={() => paste_handle("address", true)}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="birth_place">
              <span>জন্মস্থান</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="birth_place"
                name="birth_place"
                value={form_state?.birth_place ? form_state.birth_place : ""}
                className="base_input"
                placeholder="আপনার জন্মস্থান"
              />
              <button
                onClick={() => paste_handle("birth_place")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="date_of_birth">
              <span>জন্মতারিখ (ইংরেজিতে) </span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="date_of_birth"
                name="date_of_birth"
                value={
                  form_state?.date_of_birth ? form_state.date_of_birth : ""
                }
                className="base_input"
                placeholder="Example: 2000-9-16 YYYY-MM-DD"
              />
              <button
                onClick={() => paste_handle("date_of_birth")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper full">
            <label htmlFor="nid_no">
              <span>এনআইডি নাম্বার (ইংরেজিতে)</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="nid_no"
                name="nid_no"
                value={form_state?.nid_no ? form_state.nid_no : ""}
                className="base_input"
                placeholder="NID Number"
              />
              <button
                onClick={() => paste_handle("nid_no")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="nid_pin">
              <span>পিন নাম্বার (ইংরেজিতে)</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                required
                type="text"
                id="nid_pin"
                name="nid_pin"
                value={form_state?.nid_pin ? form_state.nid_pin : ""}
                className="base_input"
                placeholder="NID PIN"
              />
              <button
                onClick={() => paste_handle("nid_pin")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="barcode_data">
              <span>Barcode</span> *
            </label>
            <div className="relative">
              <input
                onChange={handle_change}
                type="text"
                id="barcode_data"
                name="barcode_data"
                value={form_state?.barcode_data ? form_state.barcode_data : ""}
                className="base_input"
                placeholder="Barcode"
              />
              <button
                onClick={() => paste_handle("barcode_data")}
                type="button"
                className="absolute inset-y-0 right-2 my-auto bg-purple-600 text-white h-fit px-2 py-1 rounded focus:ring text-sm"
              >
                Paste
              </button>
            </div>
          </div>

          <div className="base_input_wrapper full">
            <div className="flex justify-between">
              <label htmlFor="blood_group">
                <span> রক্তের গ্রুপ (Blood Group) </span>
                <small>(optional)</small>
              </label>
              <div className="blood_group_btns flex w-7/12 flex-wrap gap-2">
                <button type="button" onClick={set_blood}>
                  A+
                </button>
                <button type="button" onClick={set_blood}>
                  A-
                </button>
                <button type="button" onClick={set_blood}>
                  B+
                </button>
                <button type="button" onClick={set_blood}>
                  B-
                </button>
                <button type="button" onClick={set_blood}>
                  O+
                </button>
                <button type="button" onClick={set_blood}>
                  O-
                </button>
                <button type="button" onClick={set_blood}>
                  AB+
                </button>
                <button type="button" onClick={set_blood}>
                  AB-
                </button>
              </div>
            </div>
            <input
              onChange={handle_change}
              type="text"
              ref={blood_group_input_ref}
              id="blood_group"
              name="blood_group"
              value={form_state?.blood_group ? form_state.blood_group : ""}
              className="base_input"
              placeholder="রক্তের গ্রুপ"
            />
          </div>

          <div className="base_input_wrapper">
            <label htmlFor="nid_photo">
              <span>NID Photo</span> *
            </label>
            <input
              onChange={handle_change}
              type="file"
              id="nid_photo"
              name="nid_photo"
              className="base_input h-fit"
              placeholder="NID Photo"
            />
            <label htmlFor="nid_photo" className="w-fit h-fit">
              <div
                id="preview_nid_photo"
                className="w-[150px] h-[180px] mt-2 rounded overflow-hidden"
              >
                <div className="w-full h-full bg-black text-white flex flex-col items-center justify-center">
                  <img className="w-10" src={Upload} alt="" />
                  <p className="text-base tracking-tight my-1">
                    Recommended Size
                  </p>
                  <h6 className="text-base font-medium">150px * 200px</h6>
                </div>
              </div>
            </label>
          </div>

          <div className="base_input_wrapper">
            <div className="flex justify-between">
              <label className="hidden sm:block" htmlFor="nid_signature">
                <span>NID Signature</span> *
              </label>
              <div className="flex items-center justify-end gap-x-3">
                <label
                  htmlFor="signature_type_text"
                  className="flex items-center gap-x-1"
                  onClick={() => set_image_sign(false)}
                >
                  <small>Text Signature</small>
                  <input
                    type="radio"
                    id="signature_type_text"
                    name="signature_type"
                  />
                </label>

                <label
                  htmlFor="signature_type_image"
                  className="flex items-center gap-x-1"
                  onClick={() => set_image_sign(true)}
                >
                  <small>Image Signature</small>
                  <input
                    type="radio"
                    id="signature_type_image"
                    name="signature_type"
                    defaultChecked={true}
                  />
                </label>
              </div>
            </div>
            <input
              onChange={handle_change}
              type={image_sign ? "file" : "text"}
              id="nid_signature"
              name="nid_signature"
              className="base_input h-11"
              placeholder="NID Signature"
            />

            {image_sign && (
              <div
                id="nid_signature_image_preview"
                className="w-[300px] h-[100px]"
              >
                <label htmlFor="nid_signature">
                  <div className="w-full h-full bg-black text-white flex flex-col items-center justify-center">
                    <img className="w-10 mb-3" src={Upload} alt="" />
                    <h6 className="text-base font-medium">
                      <small>Recommended Size</small> 150 * 50px
                    </h6>
                  </div>
                </label>
              </div>
            )}
          </div>

          <div className="col-span-12 flex items-center gap-x-3">
            <button
              onClick={reset_form}
              type="button"
              className="bg-red-500 text-white px-6 py-3 rounded focus:ring text-base font-medium tracking-wider"
            >
              Reset Form
            </button>
            <button
              type="submit"
              id="form_submit_btn"
              className="bg-green-700 text-white px-6 py-3 rounded focus:ring text-base font-medium tracking-wider flex items-center justify-center gap-2"
            >
              {creating ? (
                <>
                  <span>Creating</span>
                  <div className="w-6 h-6 border-4 border-t-transparent animate-spin rounded-full" />
                </>
              ) : (
                <span>Create NID Card </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreateNID;
